select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.tag-divider{
  border-right: 1px solid #dcdfe8;
}

@media only screen and (max-width: 990px) {
  .tag-divider{
    border-bottom: 1px solid #dcdfe8;
    border-right: none;
  }
  .tags-wrapper{
    margin-top: 0;
    margin-bottom: 5rem;
    width: 13rem !important;
  }
}

@media only screen and (max-width: 375px) {
 .filter-section{
  margin-top: 2rem;
 }
}